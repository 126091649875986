.container {
  display: flex;

  .name {
    width: 30px;
    font-size: 20px;
    padding: 0 5px;
  }

  .row-numbers {


    div {
      height: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 30px;
      font-weight: 600;
      font-size: 13px;
      border: 1px solid rgba(0, 0, 0, 0.4);
    }

  }


  .columns {
    width: 100%;

    overflow-x: auto;
    padding-bottom: 10px;

    .chunks-container {
      min-width: 1500px;
      width: 100%;
      display: grid;
      grid-template-columns: repeat(36, 1fr);
    }

    .column-numbers {
      min-width: 1500px;
      width: 100%;
      display: grid;
      grid-template-columns: repeat(36, 1fr);
    }
  }
}