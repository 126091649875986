.container {
  // display: flex;
  // justify-content: space-between;
  // align-items: center;
  // border: 1px solid green;
  // width: 40%;
  height: 100%;
}




.info-table {
  background-color: white;
  align-self: flex-start;

  h1 {
    font-size: 25px;
    padding: 2% 5%;
  }

  .row {
    display: flex;
    border: 1px solid rgba(0, 0, 0, 0.3);

    svg {
      font-size: 20px;
    }

    .variable {
      width: 100%;
      max-width: 50%;
      padding: 5px;
      border-right: 1px solid rgba(0, 0, 0, 0.3);
    }

    .value {
      width: 100%;
      padding: 5px;
      background-color: rgba(0, 0, 0, 0.1);
    }
  }
}