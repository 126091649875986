$blue: rgba(49, 77, 255, 0.5);

.move-out-content {
  padding: 20px;
}

.form {
  display: grid;
  grid-template-columns: 1fr;
  .required {
    color: #ff4d4f;
    padding: 3px 5px 0px 0px;
  }
}
.container {
  height: 300px;
  overflow-y: auto;
  padding: 5px 0;
  a {
    display: block;
    color: black;
    padding: 5px 10px;
    margin-bottom: 10px;
    border: 1px solid rgba(0, 16, 81, 0.2);
  }
 
}
 .button {
    padding: 2px 10px;
    border: 1px solid $blue;
    color: rgb(74, 74, 255);
    border-radius: 5px;
    cursor: pointer;
  }