.c3d {
    margin-top: -27px;
    cursor: pointer;
    position: relative;
    width: 340px;
    .info {
        user-select: none;
        position: absolute;
        left: 80px;
        top: 70px;
        font-size: 20px;
        font-weight: 700;
    }
    &.isDragging {
        // border: 1px solid red;
        width: 100px;
    }
    .svgimage {
        width: 328px;
        height: 140px;
        &.isDragging {
            // border: 1px solid rgb(0, 17, 255);
            width: 100px;
        }
    }
}

.containers {
    margin-top: 20px;
    // min-width: 600px;
}

.container-3d {
    &::before {
      content: "";
      position: absolute;
      bottom: 3%;
      width: 30%;
      left: 10%;
      height: 30%;
      z-index: -2;
      background-color: rgba(0, 0, 0, 0.2);
      filter: blur(15px);
      transform: skew(-60deg);
      transform-origin: bottom left;
    }
  
    &.shadow-3::before {
      height: 25%;
    }
  
    &.shadow-2::before {
      height: 20%;
    }
  
    &.shadow-1::before {
      height: 10%;
    }
  
    &.shadow-0::before {
      height: 0%;
    }
  
    .containerbox {
      cursor: pointer;
      height: 70px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 18px;
      position: relative;
      z-index: 3;
      perspective: 800px;
  
      & .before {
        position: absolute;
        bottom: 100%;
        width: 100%;
        left: 0%;
        height: 50px;
        z-index: -2;
        transform: skew(-45deg);
        transform-origin: bottom left;
      }
  
      & .after {
        position: absolute;
        bottom: 0%;
        width: 50px;
        left: 100%;
        height: 100%;
        z-index: -1;
        transform: skew(0, -45deg);
        bottom: 0%;
        transform-origin: bottom left;
      }
    }
  
    .containerbox:nth-child(1) {
      background: var(--color-green);
  
      & .before {
        background: darken(#80ff7e, 20%);
      }
  
      & .after {
        background: darken(#80ff7e, 40%);
      }
    }
  
    .containerbox:nth-child(2) {
      background: var(--color-yellow);
  
      & .before {
        background: darken(#fcff73, 20%);
      }
  
      & .after {
        background: darken(#fcff73, 40%);
      }
    }
  
    .containerbox:nth-child(3) {
      background: var(--color-orange);
  
      & .before {
        background: darken(#ffc659, 20%);
      }
  
      & .after {
        background: darken(#ffc659, 30%);
      }
    }
  
    .containerbox:nth-child(4) {
      background: var(--color-red);
  
      & .before {
        background: darken(#ff5454, 5%);
      }
  
      & .after {
        background: darken(#ff5454, 30%);
      }
    }
  
    .containerbox:nth-child(5) {
      background: var(--color-coral);
  
      & .before {
        background: darken(#d6401b, 5%);
      }
  
      & .after {
        background: darken(#d6401b, 10%);
      }
    }
  
    .buffer {
      background-color: #7aa9ff !important;
  
      & .before {
        background: darken(#7aa9ff, 5%) !important;
      }
  
      & .after {
        background: darken(#7aa9ff, 30%) !important;
      }
    }
  }
  

.grid-name {
  text-align: center;
  font-weight: 700;
  font-size: 26px;
  padding-bottom: 20px;
}

.grid_3d {
  display: flex;
  align-items: start;
  height: 100%;
  gap: 10px;
}

.top_container {
  display: flex;
  justify-content: center;
  width: 100%;
  .top_container_child {
    background-color: #fff;
    border-radius: 10px;
    padding: 10px;
  }
}