* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  color: inherit;
  font-family: "Roboto";
  // outline: 1px solid rgba(0, 0, 0, 0.266);
}

body {
  background-color: var(--body-color);
}
:root {
  --color-coral: #d6401b;
  --color-red: #f06d6d;
  --color-orange: #ffc659;
  --color-yellow: #fcff73;
  --color-green: #80ff7e;
  --color-green: #80ff7e;
  --color-blue: #4f8dff;
  --body-color: #edf9ff;
}
::selection {
  background-color: #ff0a0abe;
  color: white;
}
.main-search-container td,
th {
  padding: 2px !important;
}
