.main-layout {
  padding: 20px;
  min-height: 1080px;
  max-width: 2500px;
  margin: 0 auto;
  height: 100vh;
  display: flex;
  flex-direction: column;
  gap: 2%;
}

.admin-layout {
  height: 100vh; 
  .sidebar-content {
    display: flex;
      height: calc(100% - 70px);
   
  } .content{
        height: calc(100% );
       overflow-y: auto;
       padding: 16px;
       }
}
