

.companies {    
    display: flex;
    gap: 0.3%;
    > div {
        width: 33%;
    }
}
.areas {
    display: flex;
    gap: 0.3%;
    > div {
        width: 33%;
    }  
}

.dates {
    display: flex;
    gap: 0.3%;
    > div {
        width: 33%;
    }  
}

.row {
    display: flex;
    border: 1px solid rgba(0, 0, 0, 0.3);
    svg {
      font-size: 20px;
    }
    .variable {
      width: 100%;
      max-width: 50%;
      padding: 5px  ;
      border-right: 1px solid rgba(0, 0, 0, 0.3);
    }

    .value {
      width: 100%;
      padding: 5px;
      background-color: rgba(0, 0, 0, 0.1);
    }
  }