.container {
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 5px;
  padding: 10px 20px; 
  //  width: clamp(140px,10%,200px);
  h1 {
    font-size: 28px;
    padding-bottom: 20px;
  }

  .buttons {
    display: flex;
    flex-direction: column;
    gap: 10px;

    .draggingOver {
      transform: scale(1.5);
    }
  }
}
