.container {
    margin-bottom: 40px;
    .cards {
      display: flex;
      gap: 20px;
    }
  
    .card {
      background-color: rgba(0, 30, 120, 0.045);
      padding: 10px 0;
      width: 50%;
  
  
      &>div{
        
      height: 500px;
      overflow-x: auto;
      }
    }
  
    input {
  
       width: calc(100% - 10px);
      box-shadow: 0 0 3px rgba(4, 0, 87, 0.308);
      padding: 5px 10px;
      border: 1px solid rgba(0, 73, 208, 0.358);
      margin: 2px 5px 20px;
    }
  }
  
  .button {
    box-shadow: 0 0 3px rgba(4, 0, 87, 0.308);
    padding: 5px 10px;
    border: 1px solid rgba(0, 73, 208, 0.358);
    margin: 2px 5px;
    align-items: center;
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    background-color: white;
    p {
      margin-bottom: 0;
    }
  }
  