.container {
  display: flex;
  flex-direction: column;
  // height: 400px;
  // width: 100%;
  button {
    padding: 0 10px;
    border: none;
    background-color: var(--color-red);
    color: white;
    border-radius: 10px;
    display: flex;
    align-items: center;
    font-size: 20px;
  }
  form {
    display: flex;
    padding: 5px;
    border-radius: 12px;
    background-color: white;
    input {
      outline: none;
      border: none;
      width: 100%;
      height: 30px;
      padding: 0 10px;
    }
    button {
      background-color: var(--color-red);
    }
  }
  .header {
    display: flex;
    padding: 10px 30px 10px 20px;
    gap: 2%;
    p {
      min-width: 80px;
      text-align: center;
    }
    p:last-child {
      min-width: 60px;
    }
    p:first-child {
      width: 100%;
      text-align: left;
    }
  }
  .container-items {
    overflow-y: auto;
    padding-right: 10px;
    gap: 5px;
    display: flex;
    flex-direction: column;
  }
  .item {
    display: flex;
    background-color: white;
    gap: 2%;
    padding: 2px 10px;
    border-radius: 10px;
    color: black;
    font-size: 15px;
    &:hover,
    &:focus {
      background-color: lighten($color: #ffc659, $amount: 25);
    }
    p {
      padding: 3px 0;
      min-width: 70px;
      text-align: center;
    }
    .id {
      width: 100%;
      text-align: left;
      padding-left: 3%;
    }
    .line {
      width: 1px;
      background-color: rgba(0, 0, 0, 0.3);
    }
    button {
      background-color: #24d610;
      padding-block: 3px;
      border-radius: 7px;
    }
  }
}
