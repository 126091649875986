.sector {
    width: 100%;
    height: 20px;
    border: 1px solid rgba(0, 0, 0, 0.3);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 80%;
    font-weight: 500;
  }
  