.container {
  // height: 100%;
  background-color: rgba(0, 0, 0, 0.1);
  padding: 15px;
  display: flex;
  justify-content: space-between;
  gap: 20px;
  .action_bar {
    // border: 1px solid red;
    width: 30%;
  }
  .container_search {
    width: 60%;
    flex-grow: 0;
    // border: 1px solid blue

  }

  .container_actions {
    // border: 1px solid green;
    flex-grow: 0;
    flex-shrink: 0;
    width: 8%;
  }

  // position: relative;
  //  .move {
  //   position: absolute;
  //   inset: 0;
  //   background-color: rgba(255, 255, 255, 0.98);
  //   z-index: 12;
  //   align-items: flex-start;
  //   justify-content: center;
  //   backdrop-filter: blur(5px);

  //   justify-content: space-between;
  //   padding: 20px;
  // }
  // .containerInfo {
  //   width: 50%;
  //   max-width: 300px;
  //   p {
  //     display: flex;
  //     justify-content: space-between;
  //     padding: 5px 10px;
  //     border-bottom: 1px solid rgb(0, 0, 0);
  //   }
  //   span {
  //     color: rgba(0, 0, 0, 0.722);
  //   }
  // }
}
