.wrapper {
  background: url("../../assets/images/adm.png");
  background-position: center center;
  background-size: cover;
  height: 100vh;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  .children {
    background-color: rgb(255, 255, 255);
    width: 80%;
    border-radius: 13px;
    & > div {
      padding: 50px 40px;
      @media (max-width: 600px) {
        padding: 40px 20px;
      }
    }
  }
  
}
