.container {
  display: flex;
  gap: 10px;
  align-items: center;
  img {
    width: calc(100% - 130px);
    height: 50px;
    object-fit: cover;
  }
  .yard-b{
    min-width: 80px;
  }
  .yardname {
    width: 30px;
   }
}
