.root {
    width: 200px;
    height: 100px;
    position: relative;

    .label {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        .number {
            cursor: pointer;
            background-color: #fff;
            color: #b87b7b;
            border-radius: 10px;
            padding: 5px 15px;
            font-weight: 700;
        }
    }
}