.container {
  width: 100%;
  height: 20px;
  border: 1px solid rgba(0, 0, 0, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 80%;
  font-weight: 500;
  cursor: pointer;
  &.active {
    outline: 4px ridge rgb(255, 255, 255);
    scale: 1.05;
    // outline: 4px solid white;
  }

  &:hover {
    transition: 0;
    border: 1px solid rgb(239, 239, 239);
    scale: 1.1;
    // outline: 4px solid white;
  }

  &.disabled {
    background-color: rgb(148, 148, 148) !important;
  }
}

.popover {
  display: flex;
  z-index: 1000 !important;
}

.isDraggingOver {
  transition: all 0.3 ease;
  transform: scale(1.5);
  background: #00000079 !important;
  color: #fff !important;
}

.draggable {
  cursor: grab;
}

.dragOver {
  transition: all .8 ease;
  transform: scale(1.5);
  background: #00000079 !important;
  // color: #fff !important;
}