.sidebar {
  &.active .section {
    opacity: 0;
  }
  .section {
    color: grey;
    font-family: sans-serif;
    letter-spacing: 1px;
    font-weight: 600;
    font-size: 12px;
    padding: 24px 0 12px 30px;
  }
}

.logo {
  // width: 100%;
  height: 70px;
  display: block;
  overflow: hidden;
  padding: 5px 16px;
  outline: 1px solid rgba(0, 0, 0, 0.1);
  img {
    transition: 0.1s;
    width: 180px;
  }
}
