.container {
  display: flex;
  align-items: center;
  flex-direction: column;

  img {
    width: 80%;
    margin-bottom: 30px;
  }
  form {
    // display: flex;
    // flex-direction: column;
    width: 100%;
    // gap: 10px;
    // & > input {
    //   width: 100%;
    // }
  }

  .socials {
    display: flex;
    gap: 10px;
     button {
      width: 50%;
    }
  }

  @media (max-width: 500px) {
    .socials {
      flex-direction: column;
      button {
        width: 100%;
      }
    }
  }
}
